<template>
  <span v-show="isAllowed" @click="openModal">
    <button class="btn btn-primary">
      {{ buttonName }}
    </button>
  </span>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  props: {
    allowedActions: {
      required: true,
      validator: value => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: ['ACCEPT_NEGO', 'RENEGOTIATE'],
      actionType: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async openModal() {
      this.$modal.show('nego-modal', {
        uuid: this.applicationUuid,
        actionType: this.type,
      });
    },
  },
};
</script>
